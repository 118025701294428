import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Location from "../../components/services/location_consultoria"

const TransformacionDigital = () => {
  const data = useStaticQuery(graphql`
    query Images_transformacion_digital {
      image: allFile(
        filter: { relativeDirectory: { eq: "consultoria" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Consultoría de Transformación Digital | Qualoom</title>
        <meta name="title" content="Consultoría de Transformación Digital | Qualoom"/>
        <meta name="description" content="¿Buscas especialistas en Transformación Digital para Empresas? Te ayudamos en la transformación digital efectiva de tu empresa ¡Consúltanos!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/consultoria-tecnologica/transformacion-digital/"/>
        <link rel="canonical" href="https://www.qualoom.es/consultoria-tecnologica/transformacion-digital/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/consultoria-tecnologica/transformacion-digital/"/>
        <meta property="og:title" content="Consultoría de Transformación Digital | Qualoom"/>
        <meta property="og:description" content="¿Buscas especialistas en Transformación Digital para Empresas? Te ayudamos en la transformación digital efectiva de tu empresa ¡Consúltanos!"/>

        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2KSu31ogFxZoi4VY54hFVu/24484f8e8b08fc7af28a0696a809d6ff/tw_cards_consultoria.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/consultoria-tecnologica/transformacion-digital/"/>
        <meta property="twitter:title" content="Consultoría de Transformación Digital | Qualoom"/>
        <meta property="twitter:description" content="¿Buscas especialistas en Transformación Digital para Empresas? Te ayudamos en la transformación digital efectiva de tu empresa ¡Consúltanos!"/>

        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2KSu31ogFxZoi4VY54hFVu/24484f8e8b08fc7af28a0696a809d6ff/tw_cards_consultoria.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/consultoria-tecnologica/transformacion-digital/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Consultoría tecnológica", "item": {"@id": "https://www.qualoom.es/consultoria-tecnologica/", "name": "Consultoría tecnológica"}},
              {"@type":"ListItem", "position":"3", "name":"Consultoría Tecnológica en BARCELONA", "item": {"@id": "https://www.qualoom.es/consultoria-tecnologica/transformacion-digital/", "name": "Consultoría Tecnológica en BARCELONA"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Consultoría de Transformación Digital</h1>
              <p>La consultoría tecnológica es un servicio aún muy desconocido para los autónomos y empresas que, sin embargo, puede serles de gran ayuda para digitalizarse y aumentar sus ventas.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es la Transformación Digital?</h2><hr></hr>
          <div className="section-main">
            <div className="section-text">
              <p>La transformación digital es un proceso para sustituir todos los métodos manuales, tradicionales y heredados de hacer negocios por las últimas alternativas digitales. Su objetivo es mejorar la eficiencia, aumentar el valor para el cliente, gestionar los riesgos y descubrir nuevas oportunidades de negocio.</p>
              <p>En Qualoom te acompañaremos durante todo el proceso de transformación de tu negocio para garantizar los objetivos y las necesidades de tu negocio</p>
            </div>
            <div className="section-img transformacion"></div>
          </div>
          
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Comienza el proceso de Transformación digital</h2>
          <hr></hr>
          <p>En Qualoom te ayudamos a reorganizar las estrategias y métodos de trabajo de tu empresa para que obtengas mayores beneficios optimizando la experiencia de tus clientes, digitalizando los procesos manuales de trabajo e implementando dinámicas de la tecnología.</p><br/>
          <h3>Estrategias de Transformación Digital</h3><br/>
          <p>Entendido como estrategia de transformación digital las diferentes alternativas de cómo incorporar las herramientas, soluciones y procedimientos que permiten a tu organización a mejorar sus procesos de negocio, en Qualoom entendemos que la mejor estrategia es que ofrece los mejores resultados a cada organización, pero siempre basada en los siguientes pilares:</p>
          <ul>
            <li>Visión y liderazgo</li>
            <li>Transformación digital de la experiencia del cliente/usuarios</li>
            <li>Transformación digital de personas</li>
            <li>Transformación digital de procesos</li>
            <li>Transformación digital del modelo de negocio</li>
          </ul><br/>
          <p>Cada uno de los puntos anteriores cubre el espectro global dentro de una organización de manera que si seguimos un procedimiento o buenas prácticas para la adopción de herramientas, soluciones y procedimientos para cada una de ellas, el resultado será una organización que inició y finalizó un proceso de transformación con éxito.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Necesitas una Consultoría en Transformación Digital?</h2><hr></hr>
          <div className="section-main">
            <div className="section-img consultoria"></div>
            <div className="section-text">
              <h3>Consultoría en Transformación Digital personalizada</h3>
              <p>Si quieres profundizar más en los procesos de transformación, si identificas que tu organización es ineficiente y necesitas revisar los modelos internos, herramientas y soluciones, así como los procesos de negocio, puedes ponerte en contacto con nosotros y te ayudaremos a definir la mejor estrategia de transformación junto con las mejoras soluciones tecnológicas a implantar en tu organización, además de la mejor manera para hacerlo.</p>
            </div>
          </div><br/>
          
          <h3>¿Dónde puedes encontrar los servicios de Qualoom?</h3>
          <Location/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TransformacionDigital