import React from "react"
import { FaMapMarkerAlt } from "react-icons/fa"
import "../../styles/style.css"
import Mapa from "../../../static/images/consultoria/spain.svg"

const Location = () => {
  return (
    <div className="location-section">
        <div className="location-text">
            <p>Puedes disponer de nuestros servicios desde cualquier punto de España.</p>
            <div className="location-city">
                <div className="city">
                    <a href="/consultoria-tecnologica/madrid/"><p><FaMapMarkerAlt size={13}/>Madrid</p></a>
                    <a href="/consultoria-tecnologica/barcelona/"><p><FaMapMarkerAlt size={13}/>Barcelona</p></a>
                    <a href="/consultoria-tecnologica/valencia/"><p><FaMapMarkerAlt size={13}/>Valencia</p></a>
                    <a href="/consultoria-tecnologica/bilbao/"><p><FaMapMarkerAlt size={13}/>Bilbao</p></a>
                    <a href="/consultoria-tecnologica/cordoba/"><p><FaMapMarkerAlt size={13}/>Córdoba</p></a>
                    <a href="/consultoria-tecnologica/malaga/"><p><FaMapMarkerAlt size={13}/>Málaga</p></a>
                    <a href="/consultoria-tecnologica/valladolid/"><p><FaMapMarkerAlt size={13}/>Valladolid</p></a>
                </div>
            </div>
        </div>
        <img src={Mapa} alt="Mapa de España"/>
    </div>
  )
}

export default Location